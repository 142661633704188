@charset "UTF-8";
/*!
Theme Name: Cocoon Child
Template:   cocoon-master
Version:    0.0.5
*/
/************************************
** 子テーマ用のスタイルを書く
************************************/
/*! purgecss start ignore */
.body {
  background-color: white;
}
@media (max-width: 1920px) {
  .body {
    background: inherit;
  }
}
@media (max-width: 1240px) {
  .body {
    background: inherit;
  }
}
@media (max-width: 1024px) {
  .body {
    background: inherit;
  }
}
@media (max-width: 1023px) {
  .body {
    background: inherit;
  }
}
@media (max-width: 834px) {
  .body {
    background: inherit;
  }
}
@media (max-width: 600px) {
  .body {
    background: inherit;
  }
}
@media (max-width: 480px) {
  .body {
    background: inherit;
  }
}
@media (max-width: 320px) {
  .body {
    background: inherit;
  }
}

.container {
  background-color: #f0f0f0;
}

.entry-content,
.article {
  margin-bottom: 0 !important;
}

.ghost-pc {
  display: none;
}
@media (max-width: 1240px) {
  .ghost-pc {
    display: inherit;
  }
}

.ghorst-sp {
  display: none;
}
@media (max-width: 480px) {
  .ghorst-sp {
    display: inherit;
  }
}

@media (max-width: 1024px) {
  .ghorst-padpc {
    display: none;
  }
}

.text-idt1 {
  display: inline-block;
  text-indent: -1em;
  padding-left: 1em;
}

.text-idt2 {
  display: inline-block;
  text-indent: -2em;
  padding-left: 2em;
}

.text-idt3 {
  display: inline-block;
  text-indent: -3em;
  padding-left: 3em;
}

.text-idt1 {
  display: inline-block;
  text-indent: -1em;
  padding-left: 1em;
}

.text-idt2 {
  display: inline-block;
  text-indent: -2em;
  padding-left: 2em;
}

.text-idt3 {
  display: inline-block;
  text-indent: -3em;
  padding-left: 3em;
}

/* ------------------------------------------------------------ ヘッダー */
.header-container {
  position: fixed;
  z-index: 5000;
  width: 100%;
  height: 64px;
  margin-top: -64px;
  background-color: #99ddd3;
  filter: drop-shadow(0 1px 6px rgba(20, 20, 20, 0.2));
}
.header-container .logo-7729svg {
  width: 42px;
  height: 42px;
}
@media (max-width: 480px) {
  .header-container .logo-7729svg {
    width: 32px;
    height: 32px;
  }
}
@media (max-width: 1920px) {
  .header-container {
    height: 52px;
    margin-top: -52px;
    padding-left: 8px;
    padding-right: 8px;
  }
}
@media (max-width: 1024px) {
  .header-container {
    height: 58px;
    margin-top: -58px;
  }
}
@media (max-width: 834px) {
  .header-container {
    height: 52px;
    margin-top: -52px;
  }
}
@media (max-width: 600px) {
  .header-container {
    position: relative;
    height: 52px;
    margin-top: 0;
  }
}
@media (max-width: 480px) {
  .header-container {
    height: 40.4px;
  }
}

.header-container-in {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.header-in {
  flex-direction: row !important;
  justify-content: center;
  align-items: center;
  width: auto;
  padding-top: 6px;
  padding-bottom: 6px;
}
@media (max-width: 1920px) {
  .header-in {
    padding: 0;
  }
}
@media (max-width: 1024px) {
  .header-in {
    padding-top: 4px;
    padding-bottom: 4px;
  }
}
@media (max-width: 480px) {
  .header-in {
    padding-top: 4px;
    padding-bottom: 4px;
  }
}

.header .logo-text {
  padding: 0;
}
.header .tagline {
  margin: 0;
  padding: 0 1em;
  font-size: 1em;
}
@media (max-width: 480px) {
  .header .tagline {
    display: none;
  }
}
.header .site-name-text {
  font-size: 1.6em;
  font-weight: 500;
}

.is-fp .header-container {
  width: 100vw;
  height: 100vh;
  margin-top: 0;
  position: relative;
  z-index: 5;
  background: none;
  position: relative;
  background-color: none;
  filter: none;
}
@media (max-width: 834px) {
  .is-fp .header-container {
    display: inherit;
  }
}
.is-fp .header-container-in {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: auto;
  height: 100%;
}
.is-fp .header {
  width: 90%;
  height: auto;
  padding-top: 32px;
  background: linear-gradient(to left, rgba(255, 255, 255, 0), rgba(255, 255, 255, 0.2), rgba(255, 255, 255, 0.5), rgba(255, 255, 255, 0.8), rgba(255, 255, 255, 0.8), rgba(255, 255, 255, 0.5), rgba(255, 255, 255, 0.2), rgba(255, 255, 255, 0));
}
@media (max-width: 1024px) {
  .is-fp .header {
    margin-top: -60px;
  }
}
.is-fp .header .logo-7729svg {
  width: 100px;
  height: 100px;
  margin: 2em auto 3em;
}
.is-fp .header .logo-text {
  padding-top: 16px;
}
.is-fp .header .site-name-text {
  font-size: 4rem;
  font-weight: 400;
  line-height: 1;
}
@media (max-width: 1024px) {
  .is-fp .header .site-name-text {
    font-size: 3em !important;
  }
}
@media (max-width: 480px) {
  .is-fp .header .site-name-text {
    font-size: 2em !important;
  }
}
.is-fp .header .tagline {
  font-size: 1em;
}
.is-fp .header-in {
  flex-direction: column !important;
}

/* ------------------------------------------------------------ ナビ */
.navi {
  background-color: #99ddd3;
}

.navi-in {
  width: auto;
}
.navi-in ul {
  justify-content: flex-end;
}
.navi-in > ul li {
  height: 40px;
  padding-left: 4px;
  padding-right: 4px;
  line-height: 40px;
  border-left: 1px solid white;
}
.navi-in a {
  font-size: 0.9em;
  font-weight: 500;
}
.navi-in a:hover {
  background-color: #d1f0eb;
  border-bottom: 1px solid #095c63;
  transition: 0.2s all;
}

.navilist-wide .caption-wrap,
.navilist-wide-border .caption-wrap {
  padding-right: 8px;
}
.navilist-wide .top-has-sub,
.navilist-wide-border .top-has-sub {
  padding-right: 1em !important;
}

.navilist-wide-border {
  border-right: 1px solid white;
}

.is-fp .navi {
  width: 90%;
  padding-bottom: 20px;
  background: linear-gradient(to left, rgba(255, 255, 255, 0), rgba(255, 255, 255, 0.2), rgba(255, 255, 255, 0.5), rgba(255, 255, 255, 0.8), rgba(255, 255, 255, 0.8), rgba(255, 255, 255, 0.5), rgba(255, 255, 255, 0.2), rgba(255, 255, 255, 0));
}
.is-fp .navi-in > ul {
  flex-direction: column;
  margin-left: auto;
  margin-right: auto;
}
.is-fp .navi-in > ul li {
  height: auto;
  line-height: inherit;
  border-left: none;
}
.is-fp .navi-in > .menu-header {
  display: inherit;
}
.is-fp .navi-in a {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 1em;
  font-size: 1.3em;
  font-weight: 400;
  border-bottom: none;
}
@media (max-width: 834px) {
  .is-fp .navi-in a {
    font-size: 1.2em;
  }
}
@media (max-width: 480px) {
  .is-fp .navi-in a {
    font-size: 1.1em;
  }
}
.is-fp .navi-in a:hover {
  color: white;
  border-bottom: none;
  background: linear-gradient(to left, rgba(9, 92, 99, 0), rgba(9, 92, 99, 0.2), rgba(9, 92, 99, 0.6), rgba(9, 92, 99, 0.8), rgba(9, 92, 99, 0.8), rgba(9, 92, 99, 0.6), rgba(9, 92, 99, 0.2), rgba(9, 92, 99, 0));
}
.is-fp .navi-in a::before, .is-fp .navi-in a::after {
  content: "";
  display: block;
  width: 0;
  height: 1px;
  background-color: white;
  transition: 0.5s all;
}
.is-fp .navi-in a::before {
  margin-right: 1em;
}
.is-fp .navi-in a::after {
  margin-left: 1em;
}
.is-fp .navi-in a:hover::before, .is-fp .navi-in a:hover::after {
  width: 100%;
}

/* ------------------------------------------------------------ 匕―ロー */
.area-topimage {
  width: 100vw;
  height: 100vh;
  margin-top: -100vh;
  padding: 30px;
  background: linear-gradient(-35deg, rgba(255, 98, 0, 0.4), rgba(10, 217, 162, 0.4)), url(../img/img-intro-1.jpg);
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  background-attachment: fixed;
  position: relative;
  z-index: 1;
}
@media (max-width: 834px) {
  .area-topimage {
    height: calc(100vh - 50px);
    padding: 8px;
  }
}
.area-topimage::before {
  content: "";
  display: block;
  height: 100%;
  min-height: 100%;
  box-sizing: border-box;
  border: 2px solid #35c0ab;
  background-color: rgba(249, 249, 249, 0.5);
  filter: drop-shadow(0 0 12px rgba(0, 0, 0, 0.2));
}
.area-topimage::after {
  content: "";
  display: block;
  width: 100%;
  height: 70vh;
  margin-top: -100vh;
  position: relative;
  top: 20%;
}
@keyframes Backgradation-front {
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
}

/* ------------------------------------------------------------ フロントページ　コンテント */
.content {
  min-height: 50vh;
  margin-top: 64px;
}
@media (max-width: 1920px) {
  .content {
    margin-top: 52px;
  }
}
@media (max-width: 1024px) {
  .content {
    margin-top: 58px;
  }
}
@media (max-width: 834px) {
  .content {
    margin-top: 52px;
  }
}
@media (max-width: 480px) {
  .content {
    margin-top: 0;
  }
}

.entry-content {
  padding-left: 1em;
  padding-right: 1em;
}
@media (max-width: 480px) {
  .entry-content {
    padding-left: 0;
    padding-right: 0;
  }
}

.is-fp .container {
  overflow: hidden;
}
.is-fp .content {
  margin-top: 60px;
}
.is-fp .main {
  padding-top: 0;
  background-color: inherit;
}
.is-fp .entry-content {
  padding-left: 0;
  padding-right: 0;
}
.is-fp .is-layout-flex {
  gap: 0;
}
.is-fp .btn-topage a {
  width: auto;
  background-color: #10a8b4;
  transition: all 0.2s;
}
@media (max-width: 480px) {
  .is-fp .btn-topage a {
    padding: 6px 24px;
    font-weight: 500;
  }
}
.is-fp .btn-topage a:hover {
  transition: all 0.1s;
  filter: drop-shadow(6px 6px 4px rgba(0, 0, 0, 0.3));
}

.full,
.full-top,
.full-end,
.full-end-contact,
.full-white {
  margin-right: calc(50% - 50vw);
  margin-left: calc(50% - 50vw);
  margin-bottom: 0 !important;
  width: auto;
  background-color: #f9f9f9;
}

.full-top {
  border-top: 1px solid #35c0ab;
}
@media (max-width: 480px) {
  .full-top {
    border-top: none;
  }
}

.full-end,
.full-end-contact {
  border-top: 1px solid #35c0ab;
  border-bottom: 1px solid #35c0ab;
}

.full-end {
  margin-bottom: 2em !important;
}

.full-end-contact {
  margin-bottom: 0;
  filter: drop-shadow(0 0 12px rgba(0, 0, 0, 0.2));
}

.full-white {
  padding: 20px 0 48px;
  background-color: white;
}

.innerwrap,
.innerwrap-intro,
.innerwrap-service,
.innerwrap-contact {
  margin-left: auto;
  margin-right: auto;
}

.innerwrap,
.innerwrap-service {
  width: 80%;
  max-width: 1600px;
}
@media (max-width: 1240px) {
  .innerwrap,
  .innerwrap-service {
    width: 94%;
  }
}
@media (max-width: 834px) {
  .innerwrap,
  .innerwrap-service {
    width: 96%;
  }
}

.innerwrap-intro {
  width: 42%;
  max-width: 1600px;
}
@media (max-width: 1920px) {
  .innerwrap-intro {
    width: 58%;
  }
}
@media (max-width: 834px) {
  .innerwrap-intro {
    width: 80%;
  }
}

@media (max-width: 834px) {
  .innerwrap-service {
    width: 100%;
  }
}

.innerwrap-contact {
  width: 32%;
  padding: 4em 2em 2em;
  border-left: 1px solid #35c0ab;
  border-right: 1px solid #35c0ab;
  background-color: white;
}
@media (max-width: 1920px) {
  .innerwrap-contact {
    width: 48%;
  }
}
@media (max-width: 1240px) {
  .innerwrap-contact {
    width: 70%;
  }
}
@media (max-width: 480px) {
  .innerwrap-contact {
    width: 96%;
    padding: 2em 1em 2em;
  }
}

.innerbox-outer .innerbox-image,
.innerbox-outer-rev .innerbox-image {
  flex-basis: inherit !important;
  max-width: 56%;
  padding: 36px;
}
@media (max-width: 834px) {
  .innerbox-outer .innerbox-image,
  .innerbox-outer-rev .innerbox-image {
    max-width: 52%;
  }
}
@media (max-width: 480px) {
  .innerbox-outer .innerbox-image,
  .innerbox-outer-rev .innerbox-image {
    max-width: 100%;
    padding: 12px 0 36px 0;
  }
}
.innerbox-outer .innerbox-text,
.innerbox-outer-rev .innerbox-text {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  padding: 36px;
}
@media (max-width: 480px) {
  .innerbox-outer .innerbox-text,
  .innerbox-outer-rev .innerbox-text {
    padding: 0 12px;
  }
}
@media (max-width: 480px) {
  .innerbox-outer .innerbox-text p,
  .innerbox-outer-rev .innerbox-text p {
    margin-bottom: 4px;
  }
}
.innerbox-outer .innerbox-text .button-block,
.innerbox-outer-rev .innerbox-text .button-block {
  display: block;
  width: 100%;
  text-align: right;
}

.innerbox-outer-rev {
  flex-direction: row-reverse;
}

.innerbox-outer .innerbox-image {
  border-left: 1px solid #35c0ab;
}
@media (max-width: 480px) {
  .innerbox-outer .innerbox-image {
    border-right: none;
  }
}
.innerbox-outer-rev .innerbox-image {
  border-right: 1px solid #35c0ab;
}
@media (max-width: 480px) {
  .innerbox-outer-rev .innerbox-image {
    border-left: none;
  }
}

/* ------------------------------------------------------------------------------------------ 見出し */
.article h2 {
  margin-top: 4em;
  margin-bottom: 1.5em;
  padding: 16px 28px 14px;
  font-size: 1.5em;
  line-height: 1.4;
  font-weight: 500;
  color: #03444a;
  background-color: #def7f2;
  position: relative;
}
.article h2:first-of-type {
  margin-top: 0;
}
@media (max-width: 1240px) {
  .article h2 {
    padding: 12px 28px 10px;
    font-size: 1.4em;
  }
}
@media (max-width: 1023px) {
  .article h2 {
    padding: 12px 28px 10px;
    font-size: 1.3em;
  }
}
@media (max-width: 600px) {
  .article h2 {
    padding: 10px 24px 8px;
  }
}
.article h2::before, .article h2::after {
  position: absolute;
  content: "";
  border-style: solid;
  border-color: transparent #99ddd3 transparent;
}
.article h2::before {
  top: 0;
  left: 0px;
  border-width: 0px 0px 20px 38px;
}
.article h2::after {
  bottom: 0px;
  right: 0;
  border-width: 24px 60px 0px 0px;
}

.article h3 {
  margin-top: 3em;
  margin-bottom: 1.5em;
  border-color: #99ddd3;
  border-left-color: #35c0ab;
  border-width: 1px 1px 1px 10px;
}

.article h4 {
  border-color: #99ddd3;
  border-width: 0 0 3px 0;
}

.is-fp .article h2 {
  margin-bottom: 0.5em;
  font-size: 1.6em;
  font-weight: 500;
  text-align: center;
  border-top: 1px solid #99ddd3;
  border-bottom: 1px solid #99ddd3;
  background-color: white;
}
@media (max-width: 1920px) {
  .is-fp .article h2 {
    font-size: 1.4em;
  }
}
.is-fp .article h2::before {
  border-width: 0px 0px 20px 40px;
}
.is-fp .article h2::after {
  border-width: 20px 40px 0px 0px;
}
.is-fp .innerwrap-intro h2 {
  margin-bottom: 1.2em;
}
.is-fp .innerbox-text h2,
.is-fp .innerbox-text h2 {
  width: 100%;
  padding: 16px;
  font-size: 1.3em !important;
}
@media (max-width: 1024px) {
  .is-fp .innerbox-text h2,
  .is-fp .innerbox-text h2 {
    font-size: 1.2em !important;
  }
}

.article .area-posts h2.h2-innerbox {
  padding: 16px 8px;
  text-align: center;
  font-size: 1.1em;
  font-weight: 500;
}

.sidebar h3,
.footer h3 {
  padding: 0 8px;
  text-align: center;
  font-weight: 500;
  color: #095c63;
  background-color: white;
  border-top: 2px solid #99ddd3;
  border-bottom: 2px solid #99ddd3;
  position: relative;
}
.sidebar h3::before, .sidebar h3::after,
.footer h3::before,
.footer h3::after {
  position: absolute;
  content: "";
  border-style: solid;
  border-color: transparent #99ddd3 transparent;
}
.sidebar h3::before,
.footer h3::before {
  top: 0;
  left: 0px;
  border-width: 0px 0px 20px 38px;
}
.sidebar h3::after,
.footer h3::after {
  bottom: 0px;
  right: 0;
  border-width: 20px 38px 0px 0px;
}

.footer h3 {
  background-color: #f7fcfb;
}

.h2-contact {
  text-align: center;
}

/* ------------------------------------------------------------ コンテンツ */
@media (max-width: 834px) {
  main.main {
    padding-left: 3em;
    padding-right: 3em;
  }
}
@media (max-width: 600px) {
  main.main {
    padding-top: 1em;
    padding-left: 1em;
    padding-right: 1em;
  }
}

@media (max-width: 834px) {
  .breadcrumb {
    margin-top: 0;
  }
}

/* ------------------------------------------------------------ 目次 */
.toc li {
  font-weight: bold;
}
.toc ol li ol li {
  font-weight: normal;
}

.main .toc {
  width: 100%;
  padding-left: 60px;
  padding-right: 60px;
  border-top: 3px solid #99ddd3;
  border-bottom: 3px solid #99ddd3;
  border-left: none;
  border-right: none;
}

.sidebar #toc-2 .toc-content {
  overflow-y: scroll;
  max-height: 60vh;
}
/* ------------------------------------------------------------------------------------------ ヘッダータイトル背景 */
.area-headertitle-website,
.area-headertitle-printing,
.area-headertitle-photogrammetry,
.area-headertitle-photogrammetry-3dmodel,
.area-headertitle-dx,
.area-headertitle-easyhaccp,
.area-headertitle-kyoninkashinsei,
.area-headertitle-hojokin,
.area-headertitle-choiplus,
.area-headertitle-page01 {
  height: 240px;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  position: relative;
  z-index: 3;
  opacity: 1;
}
@media (max-width: 480px) {
  .area-headertitle-website,
  .area-headertitle-printing,
  .area-headertitle-photogrammetry,
  .area-headertitle-photogrammetry-3dmodel,
  .area-headertitle-dx,
  .area-headertitle-easyhaccp,
  .area-headertitle-kyoninkashinsei,
  .area-headertitle-hojokin,
  .area-headertitle-choiplus,
  .area-headertitle-page01 {
    height: 180px;
  }
}
.area-headertitle-website::after,
.area-headertitle-printing::after,
.area-headertitle-photogrammetry::after,
.area-headertitle-photogrammetry-3dmodel::after,
.area-headertitle-dx::after,
.area-headertitle-easyhaccp::after,
.area-headertitle-kyoninkashinsei::after,
.area-headertitle-hojokin::after,
.area-headertitle-choiplus::after,
.area-headertitle-page01::after {
  content: "";
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.4);
  background-image: repeating-linear-gradient(rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2) 1px, rgba(0, 0, 0, 0) 1px, rgba(0, 0, 0, 0) 2px);
}

.area-headertitle-website {
  background-image: url(../img/image/service_website-2.jpg);
}

.area-headertitle-printing {
  background-image: url(../img/image/service_printing.jpg);
}

.area-headertitle-photogrammetry {
  background-image: url(../img/image/service_photogrammetry.jpg);
}

.area-headertitle-photogrammetry-3dmodel {
  height: 120px;
  background-image: url(../img/image/service_photogrammetry.jpg);
  background-position: center 20%;
}
@media (max-width: 834px) {
  .area-headertitle-photogrammetry-3dmodel {
    height: 60px !important;
  }
}

.area-headertitle-dx {
  background-image: url(../img/image/service_dx.jpg);
}

.area-headertitle-easyhaccp {
  background-image: url(../img/image/service_easyhaccp.jpg);
}

.area-headertitle-kyoninkashinsei {
  background-image: url(../img/image/service_kyoninkashinsei.jpg);
}

.area-headertitle-hojokin {
  background-image: url(../img/image/service_hojokin.jpg);
}

.area-headertitle-choiplus {
  background-image: url(../img/image/service_choiplus.jpg);
}

.area-headertitle-page01 {
  background-image: url(../img/image/service_page01.jpg);
}

.box-headertitle {
  display: flex;
  align-items: center;
  height: 100%;
  padding: 12px;
  position: relative;
  z-index: 6;
}
.box-headertitle h1 {
  width: 70%;
  padding: 8px 8px 8px 24px;
  font-size: 2em;
  font-weight: 400;
  background: linear-gradient(to right, white, white, rgba(255, 255, 255, 0.9), rgba(255, 255, 255, 0.8), rgba(255, 255, 255, 0.8), rgba(255, 255, 255, 0.4), rgba(255, 255, 255, 0));
}
@media (max-width: 1920px) {
  .box-headertitle h1 {
    font-size: 1.6em;
  }
}
@media (max-width: 1024px) {
  .box-headertitle h1 {
    font-size: 1.5em;
  }
}
@media (max-width: 1023px) {
  .box-headertitle h1 {
    font-size: 1.4em;
  }
}
@media (max-width: 480px) {
  .box-headertitle h1 {
    width: 96%;
  }
}

/* ------------------------------------------------------------ フロントページ　新着 */
.area-posts {
  padding-top: 2em;
  padding-bottom: 2em;
}
@media (max-width: 834px) {
  .area-posts {
    padding-bottom: 1em;
  }
}
@media (max-width: 480px) {
  .area-posts {
    padding-top: 1em;
    padding-bottom: 0;
  }
}
.area-posts .innerwrap {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
}
@media (max-width: 834px) {
  .area-posts .innerwrap {
    flex-direction: column;
  }
}
@media (max-width: 480px) {
  .area-posts .innerwrap {
    gap: 0;
  }
}
.area-posts a {
  background-color: white;
  transition: 0.2s all;
  border: 1px solid white;
}
.area-posts a:hover {
  background-color: white;
  border: 1px solid #10a8b4;
}
.area-posts a:hover img {
  transform: scale(1.1);
  transition: 0.2s all;
}
.area-posts .widget-entry-card-title {
  font-weight: 500;
}
@media (max-width: 480px) {
  .area-posts .widget-entry-card-title {
    font-size: 0.8em;
  }
}
.area-posts figure {
  margin: 0;
  overflow: hidden;
}

.box-posts {
  display: flex;
  margin-bottom: 0 !important;
}
@media (max-width: 834px) {
  .box-posts {
    justify-content: space-around;
    margin-bottom: 1em !important;
  }
}
.box-posts .new-entry-cards {
  margin-left: 8px;
}
.box-posts .new-entry-cards .swiper-wrapper {
  margin-top: 0;
  gap: 8px;
}
.box-posts .new-entry-cards a {
  margin-bottom: 0;
}
@media (max-width: 480px) {
  .box-posts .new-entry-cards .widget-entry-card-link {
    max-width: 180px;
  }
}
.box-posts h2 {
  margin: 0 !important;
  writing-mode: vertical-rl;
}

/* ------------------------------------------------------------------------------------------ 新着 カスタム投稿タイプ 3D */
.widget-entry-cards.card-large-image .a-wrap {
  max-width: inherit;
}

.widget-entry-cards.card-large-image .card-content {
  margin-bottom: 0;
  padding-top: 0;
}

.box-outer-samples-3d {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  margin-top: 2rem;
}
@media (max-width: 480px) {
  .box-outer-samples-3d {
    margin-top: 1rem;
  }
}
.box-outer-samples-3d .a-wrap {
  width: 32%;
  margin-bottom: 1.5rem;
  padding: 0;
  border-top: 1px solid white;
  border-bottom: 1px solid white;
  border-left: 1px solid white;
  border-right: 1px solid white;
  background-color: white;
  transition: all 0.2s;
  filter: drop-shadow(0px 0px 6px rgba(20, 20, 20, 0.4));
}
@media (max-width: 834px) {
  .box-outer-samples-3d .a-wrap {
    filter: drop-shadow(0px 0px 4px rgba(20, 20, 20, 0.3));
  }
}
.box-outer-samples-3d .a-wrap:hover {
  border-color: #c60000;
  background-color: white;
  border-width: 1px;
  transition: all 0.2s;
  filter: drop-shadow(0px 0px 1px rgba(20, 20, 20, 0.9));
}
@media (max-width: 834px) {
  .box-outer-samples-3d .a-wrap:hover {
    filter: drop-shadow(0px 0px 6px rgba(20, 20, 20, 0.2));
  }
}
.box-outer-samples-3d .a-wrap:hover .new-entry-card-content {
  color: #c60000;
  transition: all 0.1s;
}
.box-outer-samples-3d .a-wrap:hover img {
  transform: scale(1.1);
  transition: all 0.1s;
}
@media (max-width: 480px) {
  .box-outer-samples-3d .a-wrap {
    width: 49%;
  }
}
.box-outer-samples-3d .a-wrap figure {
  margin: 0;
  overflow: hidden;
  transition: all 0.2s;
}
.box-outer-samples-3d .a-wrap .widget-entry-card-title {
  margin-top: 8px;
  margin-bottom: auto;
  padding: 0.4em;
  font-weight: bold;
  border-top: 1px dotted #9a9a9a;
  transition: all 0.1s;
}
@media (max-width: 480px) {
  .box-outer-samples-3d .a-wrap .widget-entry-card-title {
    margin-top: 0;
    font-size: 0.9rem;
  }
}
.box-outer-samples-3d .a-wrap .box-label {
  display: block;
  width: 100%;
  text-align: right;
}
.box-outer-samples-3d .a-wrap .term-label-pg,
.box-outer-samples-3d .a-wrap .term-label-gs,
.box-outer-samples-3d .a-wrap .term-label-uc {
  margin-right: 4px;
  padding: 4px 8px;
  font-size: 10px;
}
@media (max-width: 480px) {
  .box-outer-samples-3d .a-wrap .term-label-pg,
  .box-outer-samples-3d .a-wrap .term-label-gs,
  .box-outer-samples-3d .a-wrap .term-label-uc {
    padding: 2px 4px;
    font-size: 9px;
  }
}
.box-outer-samples-3d .a-wrap .term-label-pg {
  background-color: rgb(215, 237, 255);
}
.box-outer-samples-3d .a-wrap .term-label-gs {
  background-color: rgb(236, 231, 255);
}
.box-outer-samples-3d .a-wrap .term-label-uc {
  background-color: rgb(255, 247, 237);
}

/* ------------------------------------------------------------------------------------------ ContactForm7 */
.inquiry-box {
  margin: 0 auto;
}
.inquiry-box .haveto {
  margin-left: 2em;
  padding: 4px 6px;
  font-size: 12px;
  line-height: 1;
  color: white;
  background-color: #2154b2;
}
.inquiry-box table {
  font-weight: 500;
}
.inquiry-box table tr {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}
.inquiry-box table tr:nth-of-type(2n+1) {
  background: none;
}
.inquiry-box table th {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  padding-bottom: 0;
  padding-left: 0;
  padding-right: 0;
  color: #303030;
  border: none;
  background: none;
}
.inquiry-box table th::before {
  background: none;
}
@media (max-width: 1023px) {
  .inquiry-box table th {
    padding-left: inherit;
    padding-right: inherit;
    font-size: inherit;
  }
}
.inquiry-box table th.no-color {
  display: none;
}
.inquiry-box table td {
  width: 100%;
  padding-left: 0;
  padding-right: 0;
  border: none;
}
@media (max-width: 1023px) {
  .inquiry-box table td {
    width: 100%;
    padding-left: inherit;
    padding-right: inherit;
    font-size: inherit;
  }
}
.inquiry-box table span.wpcf7-list-item {
  display: block;
  margin: 0 0 1.2em 0;
  position: relative;
}
.inquiry-box table span.wpcf7-list-item.last {
  margin-bottom: 0;
}
.inquiry-box table .wpcf7-list-item-label {
  display: inline-block;
  padding-left: 0.5em;
}
.inquiry-box .wpcf7-form-control-wrap.your-question {
  margin: 0;
}
.inquiry-box input::placeholder,
.inquiry-box textarea::placeholder {
  color: #747474;
}
.inquiry-box input[type=text],
.inquiry-box input[type=tel],
.inquiry-box input[type=email],
.inquiry-box textarea {
  width: 100%;
  font-family: "Helvetica Neue", Arial, "NotoSansCJKjp subset", "Hiragino Kaku Gothic ProN", "Hiragino Sans", "BIZ UDPGothic", Meiryo, "yu gothic medium", yugothic, sans-serif;
  border: 1px solid #d4d4d4;
}
.inquiry-box input[type=text]:focus,
.inquiry-box input[type=tel]:focus,
.inquiry-box input[type=email]:focus,
.inquiry-box textarea:focus {
  border: 1px solid #99ddd3;
}
.inquiry-box textarea {
  margin-top: 0;
}
.inquiry-box textarea {
  height: 300px;
  min-height: 300px;
}
.inquiry-box input[type=radio],
.inquiry-box input[type=checkbox] {
  margin-right: 5px;
  opacity: 1;
  width: 26px;
  height: 26px;
  background-color: white;
  border: 1px solid #99ddd3;
  border-radius: 3px;
  appearance: none;
  position: absolute;
  top: 4px;
  left: 4px;
}
.inquiry-box input[type=radio] + span,
.inquiry-box input[type=checkbox] + span {
  margin-left: 34px;
}
.inquiry-box input[type=radio]:checked,
.inquiry-box input[type=checkbox]:checked {
  background: #0c94ca;
}
.inquiry-box select {
  background-color: white;
}
.inquiry-box .wpcf7-form-control-wrap {
  margin-right: 1.5rem;
}
.inquiry-box #formbtn {
  display: block;
  padding: 15px;
  margin: 25px 0;
  width: 280px;
  font-size: 1.5rem;
  color: white;
  background: #10a8b4;
  border: 2px solid #10a8b4;
  border-radius: 2px;
  transition: 0.3s;
}
.inquiry-box #formbtn:hover {
  background: #ffb570;
  color: #5c5c5c;
  border: 2px solid #ffb570;
}
.inquiry-box .submit-check-box {
  display: flex;
  flex-direction: row;
  justify-content: center;
  width: auto;
}
.inquiry-box .submit-send-box {
  display: flex;
  justify-content: flex-end;
}
.inquiry-box .submit {
  position: relative;
}
.inquiry-box .submit .ajax-loader {
  position: absolute;
  bottom: -1rem;
}
.inquiry-box .contact-notice-footer {
  margin-top: 20px;
}
.inquiry-box .box-acceptance {
  display: flex;
}
.inquiry-box .box-acceptance .wpcf7-form-control-wrap {
  margin: -1em 3em 0 0 !important;
}
.inquiry-box .box-acceptance span {
  font-size: 0.9rem;
}
.inquiry-box .box-acceptance input[type=checkbox] {
  top: 13px;
  left: 8px;
}

.thanks {
  margin-top: 0;
  margin-bottom: 0;
}

/* ------------------------------------------------------------ reCAPTCHA */
.box-recaptcha {
  display: block;
  margin-top: 2rem;
  font-size: 0.8rem;
}
.box-recaptcha a {
  color: #747474;
}

.box-recaptcha-inq {
  display: block;
  margin-top: 2rem;
  font-size: 0.9rem;
}

.grecaptcha-badge {
  visibility: hidden;
}

/* ------------------------------------------------------------------------------------------ プライバシーポリシー */
.privacy-box,
.kiyaku-box {
  font-size: 16px;
}
.privacy-box p,
.kiyaku-box p {
  padding-left: 40px;
  margin-bottom: 2rem;
  line-height: inherit;
}

/* ------------------------------------------------------------------------------------------ サイドバー */
.sidebar .widget_categories select,
.footer .widget_categories select {
  background-color: #d1f0eb;
  border-color: #35c0ab;
}

.sidebar-scroll {
  top: 108px;
}

/* ------------------------------------------------------------------------------------------ フッター */
.footer {
  padding-bottom: 0;
  font-weight: 500;
}
.footer .footer-left,
.footer .footer-center,
.footer .footer-right {
  padding: 1em;
}
.footer .footer-left h3,
.footer .footer-center h3,
.footer .footer-right h3 {
  margin-bottom: 0.8em;
}
.footer .footer-left h3 {
  padding-left: 1.5em;
  text-align: left;
}
.footer .footer-right h3 {
  padding-right: 1.5em;
  text-align: right;
}
.footer .widget_nav_menu ul li a,
.footer .widget_nav_menu .a-wrap,
.footer .widget_new_entries ul li a,
.footer .widget_new_entries .a-wrap,
.footer .widget_categories ul li a,
.footer .widget_categories .a-wrap,
.footer .widget-entry-cards ul li a,
.footer .widget-entry-cards .a-wrap {
  padding: 2px 2px 2px 6px;
  margin-bottom: 0.5em;
  border-top: 1px solid #e6faf6;
  border-bottom: 1px dashed #095c63;
  border-left: 1px solid #e6faf6;
  border-right: 1px solid #e6faf6;
  transition: all, 0.2s;
}
@media (max-width: 480px) {
  .footer .widget_nav_menu ul li a,
  .footer .widget_nav_menu .a-wrap,
  .footer .widget_new_entries ul li a,
  .footer .widget_new_entries .a-wrap,
  .footer .widget_categories ul li a,
  .footer .widget_categories .a-wrap,
  .footer .widget-entry-cards ul li a,
  .footer .widget-entry-cards .a-wrap {
    margin-bottom: 8px;
    line-height: 1.2;
  }
}
.footer .widget_nav_menu ul li a:hover,
.footer .widget_nav_menu .a-wrap:hover,
.footer .widget_new_entries ul li a:hover,
.footer .widget_new_entries .a-wrap:hover,
.footer .widget_categories ul li a:hover,
.footer .widget_categories .a-wrap:hover,
.footer .widget-entry-cards ul li a:hover,
.footer .widget-entry-cards .a-wrap:hover {
  border: 1px solid #095c63;
  background-color: rgba(255, 255, 255, 0.2);
}
.footer .widget_nav_menu .new-entry-card-title,
.footer .widget_new_entries .new-entry-card-title,
.footer .widget_categories .new-entry-card-title,
.footer .widget-entry-cards .new-entry-card-title {
  font-size: 1.1em;
  line-height: 1.5;
}
.footer .widget_nav_menu figure,
.footer .widget_new_entries figure,
.footer .widget_categories figure,
.footer .widget-entry-cards figure {
  margin-top: 0;
  margin-right: 1em;
  line-height: 1;
}
.footer .widget_nav_menu ul li a::before,
.footer .widget_categories ul li a::before {
  content: "◆";
  margin-right: 6px;
  font-size: 0.6em;
  color: #35c0ab;
  vertical-align: middle;
}
.footer .widget_nav_menu ul li ul li a::before,
.footer .widget_categories ul li ul li a::before {
  content: "▶";
  margin-right: 6px;
  font-size: 0.5em;
  color: #35c0ab;
  vertical-align: middle;
}
.footer .widget_new_entries .a-wrap {
  padding: 6px;
}
.footer .widget_categories ul li a {
  padding: 0 1px 0 6px;
  font-size: 0.9em;
}
.footer .box-info-outer {
  text-align: right;
}
.footer .box-info-outer .logo-7729svg {
  width: 120px;
  height: 120px;
  margin: 0 auto 1em;
}
.footer .box-info-outer span {
  display: block;
  font-weight: 300;
}
.footer .box-info-outer span:first-child {
  font-size: 28px;
}
.footer .box-info-outer a {
  text-decoration: none;
}
.footer .footer-bottom {
  margin-right: calc(50% - 50vw);
  margin-left: calc(50% - 50vw);
  color: white;
  background-color: #095c63;
}

@media (max-width: 480px) {
  .copyright {
    line-height: 1.2;
  }
}

.go-to-top .go-to-top-button {
  width: 60px;
  height: 60px;
  color: white;
  border: 2px solid white;
  background-color: #095c63;
}

/* ------------------------------------------------------------ モバイルメニュー */
/* ------------------------------ モバイルメニュー */
.navi-menu-content {
  padding-top: 1em;
}
.navi-menu-content .menu-drawer {
  margin: 16px;
  padding: 24px 0 18px;
  border-top: 2px solid #35c0ab;
  border-bottom: 2px solid #35c0ab;
  position: relative;
}
.navi-menu-content .menu-drawer::before, .navi-menu-content .menu-drawer::after {
  position: absolute;
  content: "";
  border-style: solid;
  border-color: transparent #99ddd3 transparent;
  z-index: 20;
}
.navi-menu-content .menu-drawer::before {
  top: 0;
  left: 0px;
  border-width: 0px 0px 20px 38px;
}
.navi-menu-content .menu-drawer::after {
  bottom: 0px;
  right: 0;
  border-width: 24px 60px 0px 0px;
}
.navi-menu-content .menu-drawer li a {
  position: relative;
  z-index: 10;
  border-bottom: 1px dotted #095c63;
}
.navi-menu-content .menu-drawer li a::before {
  content: "◆";
  margin-right: 6px;
  font-size: 0.6em;
  color: #35c0ab;
  vertical-align: middle;
}
.navi-menu-content .menu-drawer li ul li a::before {
  content: "▶";
  margin-right: 6px;
  font-size: 0.5em;
  color: #35c0ab;
  vertical-align: middle;
}

/* ------------------------------ モバイルサイドバー */
.sidebar-menu-content {
  padding-top: 1em;
}
.sidebar-menu-content .widget_tag_cloud {
  display: none;
}

.mobile-menu-buttons {
  z-index: 6000;
}

.menu-close-button {
  font-size: 1.5em;
}

.fa-times {
  padding: 2px 8px;
  border: 1px solid #35c0ab;
  border-radius: 4px;
  background-color: #ececec;
}

.fa-times::before {
  content: "close";
  font-family: "Helvetica Neue", Arial, "NotoSansCJKjp subset", "Hiragino Kaku Gothic ProN", "Hiragino Sans", "BIZ UDPGothic", Meiryo, "yu gothic medium", yugothic, sans-serif;
  font-weight: 100;
  font-style: italic;
  color: #095c63;
}

/* ------------------------------------------------------------------------------------------ その他 */
.cat-link {
  background-color: #095c63;
}

/* ------------------------------------------------------------------------------------------ リップル効果 */
/* ------------------------------------------------------------------------------------------ マーカー */
.marker {
  background: linear-gradient(transparent 60%, #ffff99 90%);
}

.marker-red {
  background: linear-gradient(transparent 60%, #ffd0d1 90%);
}

.marker-blue {
  background: linear-gradient(transparent 60%, #C8E4F3 90%);
}

.marker-under {
  background: linear-gradient(transparent 60%, #fffabf 90%);
}

.marker-under-red {
  background: linear-gradient(transparent 60%, #fdd8dd 90%);
}

.marker-under-blue {
  background: linear-gradient(transparent 60%, #d1f0eb 90%);
}

/* ------------------------------------------------------------------------------------------ 補足説明ボックス */
/* ------------------------------------------------------------ ブロックアレンジ */
/* ------------------------------ キャプション付きボックス 赤 */
/* ------------------------------ グリーンダッシュボックス */
.box-dot-grn,
.box-dash-grn,
.box-border-grn {
  padding: 1em 1.5em;
  background-color: white;
}

.box-dot-grn {
  border: 2px dotted #99ddd3;
}

.box-dash-grn {
  border: 2px dashed #99ddd3;
}

.box-border-grn {
  border: 2px solid #99ddd3;
}

/* ------------------------------ 付箋付きボックス　ちょいプラス */
.box-sticky-grn {
  background-color: white !important;
  border: 1px solid #35c0ab !important;
  border-left-width: 7px !important;
}

.arrow-flowbox {
  padding-left: 6em;
  font-size: 1.2em;
  color: #35c0ab;
  line-height: 1;
}

/* ------------------------------ アイコン付きタブ見出しボックス　ちょいプラス（アイコン付きボックスがベース） */
.box-icon_choiplus-grn {
  background-color: #e6faf6 !important;
  border: 1px solid #99ddd3;
}
.box-icon_choiplus-grn::before {
  font-family: "icomoon";
  content: "\e97b";
  color: #35c0ab;
}

/* ------------------------------ アイコン付きボックス　ちょいプラス（アイコン付きタブボックスがベース） */
.tabbox-icon_choiplus-grn {
  position: relative;
  background-color: #f7fcfb;
}
.tabbox-icon_choiplus-grn .tab-caption-box-label {
  display: flex;
  align-items: center;
  font-size: 1.1em;
  color: #095c63;
  background-color: white;
  border: none;
  border-radius: 10px;
  position: absolute;
  top: -1em;
  left: 10px;
}
.tabbox-icon_choiplus-grn .tab-caption-box-label::before {
  font-family: "icomoon";
  content: "\e97b";
  color: #35c0ab;
  font-size: 1.1em;
}
.tabbox-icon_choiplus-grn .tab-caption-box-content {
  padding: 24px 8px 16px 20px;
  border: 2px solid #99ddd3;
  border-radius: 4px;
}
@media (max-width: 480px) {
  .tabbox-icon_choiplus-grn .tab-caption-box-content {
    padding-left: 12px;
    padding-right: 10px;
  }
}

/* ------------------------------ キャプションボックス ちょいプラス*/
.captionbox-grn,
.captionbox-half-grn,
.captionbox-icon-choiplus-grn {
  border: 1px solid #35c0ab !important;
  background-color: white !important;
  overflow: hidden;
}
.captionbox-grn .caption-box-label,
.captionbox-half-grn .caption-box-label,
.captionbox-icon-choiplus-grn .caption-box-label {
  font-weight: bold;
  background-color: #d1f0eb !important;
  background: linear-gradient(to right, #99ddd3, #99ddd3, #99ddd3, #d1f0eb, #99ddd3, #99ddd3);
}
.captionbox-grn .caption-box-content,
.captionbox-half-grn .caption-box-content,
.captionbox-icon-choiplus-grn .caption-box-content {
  padding: 1em 1.5em;
}

.captionbox-half-grn .caption-box-label {
  width: 50%;
}

.captionbox-icon-choiplus-grn {
  background-image: url(../img/parts/choiplus-logo_green_20.png);
  background-position: center right;
  background-repeat: no-repeat;
  background-size: 30%;
}

/* ------------------------------ 実績カードリスト */
.area-performance-list {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  flex-wrap: wrap;
}
@media (max-width: 1023px) {
  .area-performance-list {
    flex-direction: row;
  }
}
.area-performance-list .box-performance-list {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 48.8%;
  margin-bottom: 1em;
  padding: 1em;
  border: 1px solid #b5b5b5;
}
@media (max-width: 1023px) {
  .area-performance-list .box-performance-list {
    width: 100%;
  }
}
.area-performance-list .box-performance-list figure {
  margin-bottom: 8px;
}
.area-performance-list .box-performance-list figure {
  display: block;
  overflow: hidden;
}
.area-performance-list .box-performance-list img {
  transition: all, 0.2s;
}
.area-performance-list .box-performance-list img:hover {
  display: block;
  transform: scale(1.05);
  transition: all, 0.2s;
  opacity: 0.8;
}
.area-performance-list .text-performance-list {
  width: 100%;
}
.area-performance-list .text-performance-list .p-title {
  font-size: 1.2em;
}
.area-performance-list .text-performance-list p {
  margin-bottom: 8px;
}
.area-performance-list .text-performance-list p:first-child {
  border-bottom: 1px dotted #095c63;
}

/* ------------------------------ CTA ナナフクお問い合わせ */
.cta-nanafuku {
  position: relative;
  overflow: hidden;
}
.cta-nanafuku .cta-box {
  background-color: #095c63;
  position: relative;
  overflow: hidden;
}
.cta-nanafuku .cta-box::after {
  content: "";
  width: 100%;
  height: 100%;
  background-image: url(../img/parts/nanafuku-icon-g.png);
  background-position: center right;
  background-repeat: no-repeat;
  background-size: 36%;
  opacity: 0.4;
  position: absolute;
  top: -3em;
  right: -1em;
  z-index: 1;
}
.cta-nanafuku .cta-box .cta-heading {
  padding-top: 0;
  padding-bottom: 6px;
  color: white;
  border-bottom: 2px dotted white;
}
.cta-nanafuku .cta-box .cta-content {
  padding: 12px;
  font-size: 1.1em;
  position: relative;
  z-index: 3;
}
.cta-nanafuku .cta-box .cta-button {
  position: relative;
  z-index: 3;
}
.cta-nanafuku .cta-box .cta-button .btn {
  background-color: #ffa047;
}

/* ------------------------------------------------------------------------------------------ 印刷用 */
.unghorst-print {
  display: none;
}

@media print {
  body {
    background-color: white;
  }
  .sec-myfile .unghorst-print {
    display: block;
  }
  .sec-myfile .unghorst-print th {
    width: 300px;
  }
  .sec-myfile .unghorst-print td {
    width: 80px;
    text-align: center;
  }
  .sec-myfile .ghorst-print {
    display: none;
  }
  .sec-myfile table {
    font-size: 8px;
  }
  .sec-myfile tr:nth-of-type(2n+1) {
    background-color: white;
  }
  .sec-myfile th,
  .sec-myfile td {
    padding-top: 0;
    padding-bottom: 0;
  }
  .sec-myfile .fs-s {
    font-size: 7px;
  }
}
/*! purgecss end ignore */