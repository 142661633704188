@charset "UTF-8";

/*!
Theme Name: Cocoon Child
Template:   cocoon-master
Version:    0.0.5
*/

/************************************
** 子テーマ用のスタイルを書く
************************************/

@use "index" as i;

/*! purgecss start ignore */

.body {
    //font-family: i.$font-family;
    //text-justify: inter-ideograph;
    background-color: white;

    @include i.wpc {
        background: inherit;
    }

    @include i.pc {
        background: inherit;
    }

    @include i.padpc {
        background: inherit;
    }

    @include i.pad {
        background: inherit;
    }

    @include i.tab {
        background: inherit;
    }

    @include i.stab {
        background: inherit;
    }

    @include i.sp {
        background: inherit;
    }

    @include i.ssp {
        background: inherit;
    }
}

.container {
    background-color: #f0f0f0;
}

.entry-content,
.article {
    margin-bottom: 0 !important;
}

.ghost-pc {
    display: none;

    @include i.pc {
        display: inherit;
    }
}

.ghorst-sp {
    display: none;

    @include i.sp {
        display: inherit;
    }
}

.ghorst-padpc {

    @include i.padpc {
        display: none;
    }
}

.text-idt1 {
    display: inline-block;
    text-indent: -1em;
    padding-left: 1em;
}

.text-idt2 {
    display: inline-block;
    text-indent: -2em;
    padding-left: 2em;
}

.text-idt3 {
    display: inline-block;
    text-indent: -3em;
    padding-left: 3em;
}

.text-idt1 {
    display: inline-block;
    text-indent: -1em;
    padding-left: 1em;
}

.text-idt2 {
    display: inline-block;
    text-indent: -2em;
    padding-left: 2em;
}

.text-idt3 {
    display: inline-block;
    text-indent: -3em;
    padding-left: 3em;
}

/* ------------------------------------------------------------ ヘッダー */

.header-container {
    position: fixed;
    z-index: 5000;
    width: 100%;
    height: 64px;
    margin-top: -64px;
    //margin-left: auto;
    //margin-right: auto;
    //background-color: #f9f9f9;
    background-color: i.$primary;
    filter: drop-shadow(0 1px 6px rgba(i.$black, 0.2));

    .logo-7729svg {
        width: 42px;
        height: 42px;

        @include i.sp {
            width: 32px;
            height: 32px;
        }
    }

    @include i.wpc {
        height: 52px;
        margin-top: -52px;
        padding-left: 8px;
        padding-right: 8px;
    }

    @include i.padpc {
        height: 58px;
        margin-top: -58px;
    }

    @include i.tab {
        height: 52px;
        margin-top: -52px;
    }

    @include i.stab {
        position: relative;
        height: 52px;
        margin-top: 0;
    }

    @include i.sp {
        height: 40.4px;
    }
}

.header-container-in {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}

.header {}

.header-in {
    flex-direction: row !important;
    justify-content: center;
    align-items: center;
    width: auto;
    padding-top: 6px;
    padding-bottom: 6px;

    @include i.wpc {
        padding: 0;
    }

    @include i.padpc {
        padding-top: 4px;
        padding-bottom: 4px;
    }

    @include i.sp {
        padding-top: 4px;
        padding-bottom: 4px;
    }
}

.header {

    .logo-text {
        padding: 0;
    }

    .tagline {
        margin: 0;
        padding: 0 1em;
        font-size: 1em;

        @include i.sp {
            display: none;
        }
    }

    .site-name-text {
        font-size: 1.6em;
        font-weight: 500;
    }
}


.is-fp {

    .header-container {
        width: 100vw;
        height: 100vh;
        margin-top: 0;
        position: relative;
        z-index: 5;
        background: none;
        position: relative;
        background-color: none;
        filter: none;
        //background-image: url(../img/img-intro-1.jpg);
        //background-repeat: no-repeat;
        //background-position: center;
        //background-size: cover;
        //background-attachment: fixed;

        @include i.tab {
            display: inherit;
        }
    }

    .header-container-in {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        width: auto;
        height: 100%;
        //margin: 20px;
        //position: absolute;
        //top: 0;
        //left: 0;
        //background-color: rgba(#f9f9f9, 1);
        //filter: drop-shadow(0 0 12px rgba(black, 0.2));
    }

    .header {
        width: 90%;
        height: auto;
        padding-top: 32px;
        background: linear-gradient(to left, rgba(white, 0), rgba(white, 0.2), rgba(white, 0.5), rgba(white, .8), rgba(white, .8), rgba(white, 0.5), rgba(white, 0.2), rgba(white, 0));

        @include i.padpc {
            margin-top: -60px;
        }

        .logo-7729svg {
            width: 100px;
            height: 100px;
            margin: 2em auto 3em;
        }

        .logo-text {
            padding-top: 16px;
        }

        .site-name-text {
            font-size: 4rem;
            font-weight: 400;
            line-height: 1;

            @include i.padpc {
                font-size: 3em !important;
            }

            @include i.sp {
                font-size: 2em !important;
            }
        }

        .tagline {
            font-size: 1em;
        }
    }

    .header-in {
        flex-direction: column !important;
    }
}


/* ------------------------------------------------------------ ナビ */

.navi {
    background-color: i.$primary;
}

.navi-in {
    width: auto;

    ul {
        justify-content: flex-end;
    }

    &>ul li {
        height: 40px;
        padding-left: 4px;
        padding-right: 4px;
        line-height: 40px;
        border-left: 1px solid white;
    }

    a {
        font-size: .9em;
        font-weight: 500;
        //border-bottom: 1px solid white;

        &:hover {
            //color: i.$red;
            background-color: i.$primary-l;
            border-bottom: 1px solid i.$primary-d-d;
            transition: .2s all;
        }
    }
}

.navilist-wide,
.navilist-wide-border {
    .caption-wrap {
        padding-right: 8px;
    }

    .top-has-sub {
        padding-right: 1em !important;
    }
}

.navilist-wide-border {
    border-right: 1px solid white;
}

.is-fp {
    .navi {
        width: 90%;
        padding-bottom: 20px;
        //background: rgba(white, .6);
        background: linear-gradient(to left, rgba(white, 0), rgba(white, 0.2), rgba(white, 0.5), rgba(white, .8), rgba(white, .8), rgba(white, 0.5), rgba(white, 0.2), rgba(white, 0));
    }

    .navi-in {

        >ul {
            flex-direction: column;
            margin-left: auto;
            margin-right: auto;
        }

        &>ul li {
            height: auto;
            line-height: inherit;
            border-left: none;
        }


        >.menu-header {
            display: inherit;
        }

        a {
            display: flex;
            justify-content: center;
            align-items: center;
            margin-bottom: 1em;
            font-size: 1.3em;
            font-weight: 400;
            border-bottom: none;
            //transition: 1s all linear;

            @include i.tab {
                font-size: 1.2em;
            }

            @include i.sp {
                font-size: 1.1em;
            }

            &:hover {
                color: white;
                border-bottom: none;
                //background-color: rgba(i.$primary-d-d, .9);
                background: linear-gradient(to left, rgba(i.$primary-d-d, 0), rgba(i.$primary-d-d, 0.2), rgba(i.$primary-d-d, 0.6), rgba(i.$primary-d-d, .8), rgba(i.$primary-d-d, .8), rgba(i.$primary-d-d, 0.6), rgba(i.$primary-d-d, 0.2), rgba(i.$primary-d-d, 0));
            }

            &::before,
            &::after {
                content: "";
                display: block;
                width: 0;
                height: 1px;
                background-color: white;
                //opacity: 0;
                transition: .5s all;
            }

            &::before {
                margin-right: 1em;
            }

            &::after {
                margin-left: 1em;
            }

            &:hover::before,
            &:hover::after {
                width: 100%;
                //opacity: 1;
            }
        }
    }
}

/* ------------------------------------------------------------ 匕―ロー */

.area-topimage {
    width: 100vw;
    height: 100vh;
    margin-top: -100vh;
    padding: 30px;
    //background-color: i.$primary-d-d;
    //background-image: url(../img/img-intro-1.jpg);
    background: linear-gradient(-35deg, rgba(#ff6200, 0.4), rgba(#0ad9a2, 0.4)), url(../img/img-intro-1.jpg);
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    background-attachment: fixed;
    //outline: 2px solid #99ddd3;
    //outline-offset: -30px;
    position: relative;
    z-index: 1;

    @include i.tab {
        height: calc(100vh - 50px);
        padding: 8px;
    }

    //background: linear-gradient(225deg, #cdeee5, white);
    //background-size: 400% 400%;
    //animation: Backgradation-front 18s ease infinite;

    &::before {
        content: "";
        display: block;
        height: 100%;
        min-height: 100%;
        box-sizing: border-box;
        border: 2px solid i.$primary-d;
        //background-color: #f9f9f9;
        //background-image: url(../img/img-intro-1.jpg);
        //background-repeat: no-repeat;
        //background-position: center;
        //background-size: cover;
        background-color: rgba(#f9f9f9, .5);
        //background-blend-mode: lighten;
        //background-blend-mode: lighten;
        //mix-blend-mode: overlay;
        filter: drop-shadow(0 0 12px rgba(black, 0.2));
        //position: relative;
        //z-index: 2;
    }

    &::after {
        content: "";
        display: block;
        width: 100%;
        height: 70vh;
        margin-top: -100vh;
        //background-color: rgba(#f9f9f9, .75);
        position: relative;
        top: 20%;
    }

    @keyframes Backgradation-front {
        0% {
            background-position: 0% 50%
        }

        50% {
            background-position: 100% 50%
        }

        100% {
            background-position: 0% 50%
        }
    }
}

/* ------------------------------------------------------------ フロントページ　コンテント */

.container {
    //overflow: hidden;
}

.content {
    min-height: 50vh;
    margin-top: 64px;

    @include i.wpc {
        margin-top: 52px;
    }

    @include i.padpc {
        margin-top: 58px;
    }

    @include i.tab {
        margin-top: 52px;
    }

    @include i.sp {
        margin-top: 0;
    }
}

.content-in {
    //margin-top: 108px;
}

.entry-content {
    padding-left: 1em;
    padding-right: 1em;

    @include i.sp {
        padding-left: 0;
        padding-right: 0;
    }
}

.is-fp {

    .container {
        overflow: hidden;
    }


    .content {
        margin-top: 60px;
    }

    .main {
        padding-top: 0;
        //padding-left: 0;
        //padding-right: 0;
        background-color: inherit;
    }

    .entry-content {
        padding-left: 0;
        padding-right: 0;
    }

    //互い違いブロックのギャップ削除
    .is-layout-flex {
        gap: 0;
    }

    .btn-topage {

        a {
            width: auto;
            background-color: i.$secondary;
            transition: all .2s;

            @include i.sp {
                padding: 6px 24px;
                font-weight: 500;
            }

            &:hover {
                //opacity: 1;
                transition: all .1s;
                filter: drop-shadow(6px 6px 4px rgba(black, 0.3));
            }
        }
    }
}

.full,
.full-top,
.full-end,
.full-end-contact,
.full-white {
    margin-right: calc(50% - 50vw);
    margin-left: calc(50% - 50vw);
    margin-bottom: 0 !important;
    width: auto;
    background-color: #f9f9f9;
}

.full-top {
    border-top: 1px solid i.$primary-d;

    @include i.sp {
        border-top: none;
    }
}

.full-end,
.full-end-contact {
    border-top: 1px solid i.$primary-d;
    border-bottom: 1px solid i.$primary-d;
}

.full-end {
    margin-bottom: 2em !important;
}

.full-end-contact {
    margin-bottom: 0;
    filter: drop-shadow(0 0 12px rgba(black, 0.2));
}

.full-white {
    padding: 20px 0 48px;
    background-color: white;
}

.innerwrap,
.innerwrap-intro,
.innerwrap-service,
.innerwrap-contact {
    margin-left: auto;
    margin-right: auto;
}

.innerwrap,
.innerwrap-service {
    width: 80%;
    max-width: 1600px;

    @include i.pc {
        width: 94%;
    }

    @include i.tab {
        width: 96%;
    }
}

.innerwrap-intro {
    width: 42%;
    max-width: 1600px;

    @include i.wpc {
        width: 58%;
    }

    @include i.tab {
        width: 80%;
    }
}

.innerwrap-service {

    @include i.tab {
        width: 100%;
    }
}

.innerwrap-contact {
    width: 32%;
    padding: 4em 2em 2em;
    border-left: 1px solid i.$primary-d;
    border-right: 1px solid i.$primary-d;
    background-color: white;

    @include i.wpc {
        width: 48%;
    }

    @include i.pc {
        width: 70%;
    }

    @include i.sp {
        width: 96%;
        padding: 2em 1em 2em;
    }
}

.innerbox-outer,
.innerbox-outer-rev {

    .innerbox-image {
        flex-basis: inherit !important;
        max-width: 56%;
        padding: 36px;
        //background-color: white;

        @include i.tab {
            max-width: 52%;
        }

        @include i.sp {
            max-width: 100%;
            padding: 12px 0 36px 0;
        }
    }

    .innerbox-text {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: flex-start;
        padding: 36px;

        @include i.sp {
            padding: 0 12px;
        }

        p {
            @include i.sp {
                margin-bottom: 4px;
            }

        }

        .button-block {
            display: block;
            width: 100%;
            text-align: right;
        }
    }
}

.innerbox-outer-rev {
    flex-direction: row-reverse;
}

.innerbox-outer {

    .innerbox-image {
        border-left: 1px solid i.$primary-d;

        @include i.sp {
            border-right: none;
        }
    }

    @include i.sp {
        //flex-direction: column-reverse;
    }
}

.innerbox-outer-rev {

    .innerbox-image {
        border-right: 1px solid i.$primary-d;

        @include i.sp {
            border-left: none;
        }
    }
}

/* ------------------------------------------------------------------------------------------ 見出し */

.entry-title {}

.article h2 {
    margin-top: 4em;
    margin-bottom: 1.5em;
    padding: 16px 28px 14px;
    font-size: 1.5em;
    line-height: 1.4;
    font-weight: 500;
    color: i.$primary-d-d-mds;
    background-color: i.$primary-l-d-mds;
    //background-color: white;
    //border-top: 1px solid i.$primary-d;
    //border-bottom: 1px solid i.$primary-d;
    position: relative;

    &:first-of-type {
        margin-top: 0;
    }

    @include i.pc {
        padding: 12px 28px 10px;
        font-size: 1.4em;
    }

    @include i.pad {
        padding: 12px 28px 10px;
        font-size: 1.3em;
    }

    @include i.stab {
        padding: 10px 24px 8px;
    }

    &::before,
    &::after {
        position: absolute;
        content: '';
        border-style: solid;
        border-color: transparent i.$primary transparent;
    }

    &::before {
        top: 0;
        left: 0px;
        border-width: 0px 0px 20px 38px;
    }

    &::after {
        bottom: 0px;
        right: 0;
        border-width: 24px 60px 0px 0px;
    }
}

.article h3 {
    margin-top: 3em;
    margin-bottom: 1.5em;
    border-color: i.$primary;
    border-left-color: i.$primary-d;
    border-width: 1px 1px 1px 10px;
}

.article h4 {
    border-color: i.$primary;
    border-width: 0 0 3px 0;
}

.is-fp {
    .article h2 {
        //width: 100%;
        //padding-right: 24px;
        //padding-left: 1.5em !important;
        margin-bottom: .5em;
        font-size: 1.6em;
        font-weight: 500;
        text-align: center;
        //font-weight: 500;
        //color: i.$primary-d-d;
        border-top: 1px solid i.$primary;
        border-bottom: 1px solid i.$primary;
        background-color: white;

        @include i.wpc {
            font-size: 1.4em;
        }

        &::before {
            border-width: 0px 0px 20px 40px;
        }

        &::after {
            border-width: 20px 40px 0px 0px;
        }
    }

    .innerwrap-intro {

        h2 {
            margin-bottom: 1.2em;
        }
    }

    .innerbox-text,
    .innerbox-text {

        h2 {
            width: 100%;
            padding: 16px;
            font-size: 1.3em !important;

            @include i.padpc {
                font-size: 1.2em !important;
            }
        }
    }
}

.article .area-posts h2.h2-innerbox {
    padding: 16px 8px;
    text-align: center;
    font-size: 1.1em;
    font-weight: 500;
    //border-bottom: 1px solid i.$primary-d;
}

.sidebar h3,
.footer h3 {
    padding: 0 8px;
    //font-size: 1.5em;
    text-align: center;
    font-weight: 500;
    color: i.$primary-d-d;
    background-color: white;
    border-top: 2px solid i.$primary;
    border-bottom: 2px solid i.$primary;
    position: relative;

    &::before,
    &::after {
        position: absolute;
        content: '';
        border-style: solid;
        border-color: transparent i.$primary transparent;
    }

    &::before {
        top: 0;
        left: 0px;
        border-width: 0px 0px 20px 38px;
    }

    &::after {
        bottom: 0px;
        right: 0;
        border-width: 20px 38px 0px 0px;
    }
}

.footer h3 {
    background-color: i.$primary-l-l;
}

.h2-contact {
    text-align: center;
}

/* ------------------------------------------------------------ コンテンツ */

main.main {

    @include i.tab {
        padding-left: 3em;
        padding-right: 3em;
    }

    @include i.stab {
        padding-top: 1em;
        padding-left: 1em;
        padding-right: 1em;
    }
}

.breadcrumb {

    @include i.tab {
        margin-top: 0;
    }
}

/* ------------------------------------------------------------ 目次 */

.toc {
    li {
        font-weight: bold;
    }

    ol li ol li {
        font-weight: normal;
    }
}

.main {
    .toc {
        width: 100%;
        padding-left: 60px;
        padding-right: 60px;
        border-top: 3px solid i.$primary;
        border-bottom: 3px solid i.$primary;
        border-left: none;
        border-right: none;
    }
}

.sidebar {

    #toc-2 .toc-content {
        overflow-y: scroll;
        max-height: 60vh;
    }

    .toc-content::-webkit-scrollbar {
        //width: 12px;
    }

    .toc-content::-webkit-scrollbar-thumb {
        //background-color: rgba(i.$primary-d-d, .4);
    }
}

/* ------------------------------------------------------------------------------------------ ヘッダータイトル背景 */

.area-headertitle-website,
.area-headertitle-printing,
.area-headertitle-photogrammetry,
.area-headertitle-photogrammetry-3dmodel,
.area-headertitle-dx,
.area-headertitle-easyhaccp,
.area-headertitle-kyoninkashinsei,
.area-headertitle-hojokin,
.area-headertitle-choiplus,
.area-headertitle-page01 {
    height: 240px;
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    position: relative;
    z-index: 3;
    opacity: 1;

    @include i.sp {
        height: 180px;
    }

    &::after {
        content: '';
        display: block;
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: rgba(0, 0, 0, 0.4);
        background-image: repeating-linear-gradient(rgba(0, 0, 0, .2), rgba(0, 0, 0, .2) 1px, rgba(0, 0, 0, 0) 1px, rgba(0, 0, 0, 0) 2px);
    }
}

.area-headertitle-website {
    background-image: url(../img/image/service_website-2.jpg);
}

.area-headertitle-printing {
    background-image: url(../img/image/service_printing.jpg);
}

.area-headertitle-photogrammetry {
    background-image: url(../img/image/service_photogrammetry.jpg);
}

.area-headertitle-photogrammetry-3dmodel {
    height: 120px;
    background-image: url(../img/image/service_photogrammetry.jpg);
    background-position: center 20%;

    @include i.tab {
        height: 60px !important;
    }
}

.area-headertitle-dx {
    background-image: url(../img/image/service_dx.jpg);
}

.area-headertitle-easyhaccp {
    background-image: url(../img/image/service_easyhaccp.jpg);
}

.area-headertitle-kyoninkashinsei {
    background-image: url(../img/image/service_kyoninkashinsei.jpg);
}

.area-headertitle-hojokin {
    background-image: url(../img/image/service_hojokin.jpg);
}

.area-headertitle-choiplus {
    background-image: url(../img/image/service_choiplus.jpg);
}

.area-headertitle-page01 {
    background-image: url(../img/image/service_page01.jpg);
}

.box-headertitle {
    display: flex;
    align-items: center;
    height: 100%;
    padding: 12px;
    position: relative;
    z-index: 6;

    h1 {
        width: 70%;
        padding: 8px 8px 8px 24px;
        font-size: 2em;
        font-weight: 400;
        //background-color: white;
        background: linear-gradient(to right, rgba(white, 1), rgba(white, 1), rgba(white, .9), rgba(white, .8), rgba(white, 0.8), rgba(white, 0.4), rgba(white, 0));

        @include i.wpc {
            font-size: 1.6em;
        }

        @include i.padpc {
            font-size: 1.5em;
        }

        @include i.pad {
            font-size: 1.4em;
        }

        @include i.sp {
            width: 96%;
        }
    }
}

/* ------------------------------------------------------------ フロントページ　新着 */

.area-posts {
    padding-top: 2em;
    padding-bottom: 2em;

    @include i.tab {
        padding-bottom: 1em;
    }

    @include i.sp {
        padding-top: 1em;
        padding-bottom: 0;
    }

    .innerwrap {
        display: flex;
        flex-direction: row;
        justify-content: space-around;

        @include i.tab {
            flex-direction: column;
        }

        @include i.sp {
            gap: 0;
        }
    }

    a {
        background-color: white;
        transition: .2s all;
        border: 1px solid white;

        &:hover {
            background-color: white;
            border: 1px solid i.$secondary;

            img {
                transform: scale(1.1);
                transition: .2s all;
            }
        }
    }

    .widget-entry-card-title {
        font-weight: 500;

        @include i.sp {
            font-size: .8em;
        }
    }

    figure {
        margin: 0;
        overflow: hidden;
    }
}

.box-posts {
    display: flex;
    margin-bottom: 0 !important;

    @include i.tab {
        justify-content: space-around;
        margin-bottom: 1em !important;
    }

    .new-entry-cards {
        margin-left: 8px;

        .swiper-wrapper {
            margin-top: 0;
            gap: 8px;
        }

        .swiper-button-next {
            //display: none;
        }

        a {
            margin-bottom: 0;
        }

        .widget-entry-card-link {

            @include i.sp {
                max-width: 180px;
            }
        }
    }

    h2 {
        margin: 0 !important;
        writing-mode: vertical-rl;
    }
}

/* ------------------------------------------------------------------------------------------ 新着 カスタム投稿タイプ 3D */

.widget-entry-cards.card-large-image .a-wrap {
    max-width: inherit;
}

.widget-entry-cards.card-large-image .card-content {
    margin-bottom: 0;
    padding-top: 0;
    //position: relative;
}

.box-outer-samples-3d {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    margin-top: 2rem;

    @include i.sp {
        margin-top: 1rem;
    }

    .a-wrap {
        width: 32%;
        margin-bottom: 1.5rem;
        padding: 0;
        border-top: 1px solid white;
        border-bottom: 1px solid white;
        border-left: 1px solid white;
        border-right: 1px solid white;
        background-color: white;
        transition: all .2s;
        filter: drop-shadow(0px 0px 6px rgba(i.$black, 0.4));

        @include i.tab {
            filter: drop-shadow(0px 0px 4px rgba(i.$black, 0.3));
        }

        &:hover {
            border-color: i.$red;
            background-color: white;
            border-width: 1px;
            transition: all .2s;
            filter: drop-shadow(0px 0px 1px rgba(i.$black, 0.9));

            @include i.tab {
                filter: drop-shadow(0px 0px 6px rgba(i.$black, 0.2));
            }

            .new-entry-card-content {
                color: i.$red;
                transition: all .1s;
            }

            img {
                transform: scale(1.1);
                transition: all .1s;
            }
        }

        @include i.sp {
            width: 49%;
        }

        figure {
            margin: 0;
            overflow: hidden;
            transition: all .2s;
        }

        .widget-entry-card-title {
            margin-top: 8px;
            margin-bottom: auto;
            padding: 0.4em;
            font-weight: bold;
            border-top: 1px dotted i.$gray-600;
            transition: all .1s;

            @include i.sp {
                margin-top: 0;
                font-size: .9rem;
            }
        }

        .box-label {
            display: block;
            width: 100%;
            text-align: right;
        }

        .term-label-pg,
        .term-label-gs,
        .term-label-uc {
            //position: absolute;
            //bottom: 0.4em;
            //right: 0;
            margin-right: 4px;
            padding: 4px 8px;
            font-size: 10px;

            @include i.sp {
                padding: 2px 4px;
                font-size: 9px;
            }
        }

        .term-label-pg {
            //border: 1px solid rgb(9, 120, 211);
            background-color: rgb(215, 237, 255);
        }

        .term-label-gs {
            //border: 1px solid rgb(114, 47, 238);
            background-color: rgb(236, 231, 255);
        }

        .term-label-uc {
            //border: 1px solid rgb(238, 144, 21);
            background-color: rgb(255, 247, 237);
        }
    }
}

/* ------------------------------------------------------------------------------------------ ContactForm7 */

.inquiry-box {
    margin: 0 auto;

    .haveto {
        margin-left: 2em;
        padding: 4px 6px;
        font-size: 12px;
        line-height: 1;
        color: white;
        background-color: i.$nf-blue;
    }

    table {
        font-weight: 500;

        tr {
            display: flex;
            flex-direction: column;
            align-items: flex-start;
        }

        tr:nth-of-type(2n+1) {
            background: none;
        }

        th {
            display: flex;
            justify-content: flex-start;
            align-items: center;
            width: 100%;
            padding-bottom: 0;
            padding-left: 0;
            padding-right: 0;
            color: i.$gray-900;
            border: none;
            background: none;

            &::before {
                background: none;
            }

            @include i.pad {
                padding-left: inherit;
                padding-right: inherit;
                font-size: inherit;
            }
        }

        th.no-color {
            display: none;
        }

        td {
            width: 100%;
            padding-left: 0;
            padding-right: 0;
            border: none;

            @include i.pad {
                width: 100%;
                padding-left: inherit;
                padding-right: inherit;
                font-size: inherit;
            }
        }

        span.wpcf7-list-item {
            display: block;
            margin: 0 0 1.2em 0;
            position: relative;
        }

        span.wpcf7-list-item.last {
            margin-bottom: 0;
        }

        .wpcf7-list-item-label {
            display: inline-block;
            padding-left: .5em;
        }
    }

    .wpcf7-form-control-wrap.your-question {
        margin: 0;
    }

    input::placeholder,
    textarea::placeholder {
        color: i.$gray-700;
    }

    input[type="text"],
    input[type="tel"],
    input[type="email"],
    textarea {
        width: 100%;
        font-family: i.$font-family-base;
        border: 1px solid i.$gray-400;

        &:focus {
            border: 1px solid i.$primary;
        }
    }

    textarea {
        margin-top: 0;
    }

    textarea {
        height: 300px;
        min-height: 300px;
    }

    input[type="radio"],
    input[type="checkbox"] {
        margin-right: 5px;
        opacity: 1;
        width: 26px;
        height: 26px;
        background-color: white;
        border: 1px solid i.$primary;
        border-radius: 3px;
        appearance: none;
        position: absolute;
        top: 4px;
        left: 4px;
    }

    input[type="radio"]+span,
    input[type="checkbox"]+span {
        margin-left: 34px;
    }

    input[type="radio"]:checked,
    input[type="checkbox"]:checked {
        background: i.$quinary-l;
    }

    select {
        background-color: white;
        //border-color: i.$primary-d;
    }

    .wpcf7-form-control-wrap {
        margin-right: 1.5rem;
    }

    #formbtn {
        display: block;
        padding: 15px;
        margin: 25px 0;
        width: 280px;
        font-size: 1.5rem;
        color: white;
        background: i.$secondary;
        border: 2px solid i.$secondary;
        border-radius: 2px;
        transition: .3s;
    }

    #formbtn:hover {
        background: i.$quaternary-l;
        color: i.$gray-800;
        border: 2px solid i.$quaternary-l;
    }

    .submit-check-box {
        display: flex;
        flex-direction: row;
        justify-content: center;
        width: auto;
    }

    .submit-send-box {
        display: flex;
        justify-content: flex-end;
    }

    .submit {
        position: relative;

        .ajax-loader {
            position: absolute;
            bottom: -1rem;
        }
    }

    .contact-notice-footer {
        margin-top: 20px;
    }

    .box-acceptance {
        display: flex;

        .wpcf7-form-control-wrap {
            margin: -1em 3em 0 0 !important;
        }

        span {
            font-size: .9rem;
        }

        input[type="checkbox"] {
            top: 13px;
            left: 8px;
        }
    }
}

.thanks {
    margin-top: 0;
    margin-bottom: 0;
}

/* ------------------------------------------------------------ reCAPTCHA */

.box-recaptcha {
    display: block;
    margin-top: 2rem;
    font-size: .8rem;

    a {
        color: i.$gray-700;
    }
}

.box-recaptcha-inq {
    display: block;
    margin-top: 2rem;
    font-size: .9rem;
}

.grecaptcha-badge {
    visibility: hidden;
}

/* ------------------------------------------------------------------------------------------ プライバシーポリシー */

.privacy-box,
.kiyaku-box {
    //width: 90%;
    //margin: 2rem;
    font-size: 16px;

    p {
        padding-left: 40px;
        margin-bottom: 2rem;
        line-height: inherit;
    }
}

/* ------------------------------------------------------------------------------------------ サイドバー */

.sidebar,
.footer {

    .widget_categories {
        select {
            background-color: i.$primary-l;
            border-color: i.$primary-d;
        }
    }
}

.sidebar-scroll {
    top: 108px;
}

/* ------------------------------------------------------------------------------------------ フッター */

.footer {
    padding-bottom: 0;
    font-weight: 500;

    .footer-left,
    .footer-center,
    .footer-right {
        padding: 1em;

        h3 {
            margin-bottom: .8em;
        }
    }

    .footer-left {

        h3 {
            padding-left: 1.5em;
            text-align: left;
        }
    }

    .footer-right {

        h3 {
            padding-right: 1.5em;
            text-align: right;
        }
    }

    .widget_nav_menu,
    .widget_new_entries,
    .widget_categories,
    .widget-entry-cards {

        ul li a,
        .a-wrap {
            padding: 2px 2px 2px 6px;
            margin-bottom: .5em;
            //background-color: #f9f9f9;
            border-top: 1px solid #e6faf6;
            border-bottom: 1px dashed i.$primary-d-d;
            border-left: 1px solid #e6faf6;
            border-right: 1px solid #e6faf6;
            transition: all, .2s;

            @include i.sp {
                margin-bottom: 8px;
                line-height: 1.2;
            }

            &:hover {
                border: 1px solid i.$primary-d-d;
                background-color: rgba(white, .2);
            }
        }

        .new-entry-card-title {
            font-size: 1.1em;
            line-height: 1.5;
        }

        figure {
            margin-top: 0;
            margin-right: 1em;
            line-height: 1;
        }
    }

    .widget_nav_menu,
    .widget_categories {

        ul li a::before {
            content: '◆';
            margin-right: 6px;
            font-size: .6em;
            color: i.$primary-d;
            vertical-align: middle;
        }

        ul li ul li a::before {
            content: '▶';
            margin-right: 6px;
            font-size: .5em;
            color: i.$primary-d;
            vertical-align: middle;
        }
    }

    .widget_new_entries {

        .a-wrap {
            padding: 6px;
        }
    }

    .widget_categories {

        ul li a {
            padding: 0 1px 0 6px;
            font-size: .9em;
        }
    }

    .box-info-outer {
        text-align: right;

        .logo-7729svg {
            width: 120px;
            height: 120px;
            margin: 0 auto 1em;
        }

        span {
            display: block;
            font-weight: 300;

            &:first-child {
                font-size: 28px;
            }
        }

        a {
            text-decoration: none;
        }
    }

    .footer-bottom {
        margin-right: calc(50% - 50vw);
        margin-left: calc(50% - 50vw);
        color: white;
        background-color: i.$primary-d-d;
    }
}



.copyright {

    @include i.sp {
        line-height: 1.2;
    }
}

.go-to-top {

    .go-to-top-button {
        width: 60px;
        height: 60px;
        color: white;
        border: 2px solid white;
        background-color: i.$primary-d-d;
        //border-radius: 50%;
    }
}

/* ------------------------------------------------------------ モバイルメニュー */

/* ------------------------------ モバイルメニュー */

.navi-menu-content {
    padding-top: 1em;

    .menu-drawer {
        margin: 16px;
        padding: 24px 0 18px;
        border-top: 2px solid i.$primary-d;
        border-bottom: 2px solid i.$primary-d;
        position: relative;

        &::before,
        &::after {
            position: absolute;
            content: '';
            border-style: solid;
            border-color: transparent i.$primary transparent;
            z-index: 20;
        }

        &::before {
            top: 0;
            left: 0px;
            border-width: 0px 0px 20px 38px;
        }

        &::after {
            bottom: 0px;
            right: 0;
            border-width: 24px 60px 0px 0px;
        }

        li a {
            position: relative;
            z-index: 10;
            border-bottom: 1px dotted i.$primary-d-d;

            &::before {
                content: '◆';
                //width: 1em;
                //height: 1em;
                margin-right: 6px;
                font-size: .6em;
                color: i.$primary-d;
                vertical-align: middle;
            }

        }

        li ul li a {

            &::before {
                content: '▶';
                margin-right: 6px;
                font-size: .5em;
                color: i.$primary-d;
                vertical-align: middle;
            }
        }
    }
}

/* ------------------------------ モバイルサイドバー */
.sidebar-menu-content {
    padding-top: 1em;

    .widget_tag_cloud {
        display: none;
    }
}

.mobile-menu-buttons {
    z-index: 6000;
}

.menu-close-button {
    font-size: 1.5em;
}

.fa-times {
    padding: 2px 8px;
    border: 1px solid i.$primary-d;
    border-radius: 4px;
    background-color: i.$gray-200;
}

.fa-times::before {
    content: 'close';
    font-family: i.$font-family-base;
    //font-size: .8em;
    font-weight: 100;
    font-style: italic;
    color: i.$primary-d-d;
    ;
}

/* ------------------------------------------------------------------------------------------ その他 */

.cat-link {
    background-color: i.$primary-d-d;
}

/* ------------------------------------------------------------------------------------------ リップル効果 */

/* ------------------------------------------------------------------------------------------ マーカー */
.marker {
    background: linear-gradient(transparent 60%, #ffff99 90%);
}

.marker-red {
    background: linear-gradient(transparent 60%, #ffd0d1 90%);
}

.marker-blue {
    //background: linear-gradient(transparent 60%, #a8dafb 90%);
    background: linear-gradient(transparent 60%, #C8E4F3 90%);
}

.marker-under {
    background: linear-gradient(transparent 60%, #fffabf 90%);
}

.marker-under-red {
    background: linear-gradient(transparent 60%, #fdd8dd 90%);
}

.marker-under-blue {
    background: linear-gradient(transparent 60%, i.$primary-l 90%);
}

/* ------------------------------------------------------------------------------------------ 補足説明ボックス */


/* ------------------------------------------------------------ ブロックアレンジ */
/* ------------------------------ キャプション付きボックス 赤 */


/* ------------------------------ グリーンダッシュボックス */
.box-dot-grn,
.box-dash-grn,
.box-border-grn {
    padding: 1em 1.5em;
    background-color: white;
}

.box-dot-grn {
    border: 2px dotted i.$primary;
}

.box-dash-grn {
    border: 2px dashed i.$primary;
}

.box-border-grn {
    border: 2px solid i.$primary;
}

/* ------------------------------ 付箋付きボックス　ちょいプラス */

.box-sticky-grn {
    background-color: white !important;
    border: 1px solid i.$primary-d !important;
    border-left-width: 7px !important;
}

.arrow-flowbox {
    padding-left: 6em;
    font-size: 1.2em;
    color: i.$primary-d;
    line-height: 1;
}

/* ------------------------------ アイコン付きタブ見出しボックス　ちょいプラス（アイコン付きボックスがベース） */
.box-icon_choiplus-grn {
    background-color: i.$primary-l-d !important;
    border: 1px solid i.$primary;

    &::before {
        font-family: 'icomoon';
        content: '\e97b';
        color: i.$primary-d;
        //font-size: 1.1em;
    }
}

/* ------------------------------ アイコン付きボックス　ちょいプラス（アイコン付きタブボックスがベース） */
.tabbox-icon_choiplus-grn {
    position: relative;
    background-color: i.$primary-l-l;

    .tab-caption-box-label {
        display: flex;
        align-items: center;
        font-size: 1.1em;
        //font-weight: 700;
        color: i.$primary-d-d;
        background-color: white;
        border: none;
        border-radius: 10px;
        position: absolute;
        top: -1em;
        left: 10px;

        &::before {
            font-family: 'icomoon';
            content: '\e97b';
            color: i.$primary-d;
            font-size: 1.1em;
        }
    }

    .tab-caption-box-content {
        padding: 24px 8px 16px 20px;
        border: 2px solid i.$primary;
        border-radius: 4px;

        @include i.sp {
            padding-left: 12px;
            padding-right: 10px;
        }
    }
}

/* ------------------------------ キャプションボックス ちょいプラス*/

.captionbox-grn,
.captionbox-half-grn,
.captionbox-icon-choiplus-grn {
    border: 1px solid i.$primary-d !important;
    background-color: white !important;
    overflow: hidden;

    .caption-box-label {
        //color: white;
        //font-size: 1.1em;
        font-weight: bold;
        background-color: i.$primary-l !important;
        background: linear-gradient(to right, rgba(i.$primary, 1), rgba(i.$primary, 1), rgba(i.$primary, 1), rgba(i.$primary-l, 1), rgba(i.$primary, 1), rgba(i.$primary, 1));
    }

    .caption-box-content {
        padding: 1em 1.5em;
    }
}

.captionbox-half-grn {

    .caption-box-label {
        width: 50%;
        //border-radius: 4px 0 4px 0;
    }
}

.captionbox-icon-choiplus-grn {
    background-image: url(../img/parts/choiplus-logo_green_20.png);
    background-position: center right;
    background-repeat: no-repeat;
    background-size: 30%;
}


/* ------------------------------ 実績カードリスト */
.area-performance-list {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    //align-items: flex-start;
    flex-wrap: wrap;

    @include i.pad {
        flex-direction: row;
    }

    .box-performance-list {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        width: 48.8%;
        margin-bottom: 1em;
        padding: 1em;
        border: 1px solid i.$gray-500;

        @include i.pad {
            width: 100%;
        }

        figure {
            margin-bottom: 8px;
        }

        figure {
            display: block;
            overflow: hidden;
        }

        img {
            transition: all, .2s;

            &:hover {
                display: block;
                transform: scale(1.05);
                transition: all, .2s;
                opacity: .8;
            }
        }
    }

    .text-performance-list {
        width: 100%;

        .p-title {
            font-size: 1.2em;
        }

        p {
            margin-bottom: 8px;
        }

        p:first-child {
            border-bottom: 1px dotted i.$primary-d-d;
        }
    }
}

/* ------------------------------ CTA ナナフクお問い合わせ */

.cta-nanafuku {
    position: relative;
    overflow: hidden;

    .cta-box {
        //olor: i.$primary-d-d;
        background-color: i.$primary-d-d;
        position: relative;
        overflow: hidden;

        &::after {
            content: '';
            width: 100%;
            height: 100%;
            background-image: url(../img/parts/nanafuku-icon-g.png);
            background-position: center right;
            background-repeat: no-repeat;
            background-size: 36%;
            opacity: .4;
            position: absolute;
            top: -3em;
            right: -1em;
            z-index: 1;
        }

        .cta-heading {
            padding-top: 0;
            padding-bottom: 6px;
            color: white;
            border-bottom: 2px dotted white;
            //background-color: i.$primary-d-d;
        }

        .cta-content {
            padding: 12px;
            font-size: 1.1em;
            position: relative;
            z-index: 3;
        }

        .cta-button {
            position: relative;
            z-index: 3;

            .btn {
                background-color: i.$quaternary;
            }
        }
    }
}

/* ------------------------------------------------------------------------------------------ 印刷用 */

.unghorst-print {

    display: none;
}

@media print {

    body {
        background-color: white;
    }

    .sec-myfile {

        .unghorst-print {
            display: block;

            th {
                width: 300px;
            }

            td {
                width: 80px;
                text-align: center;
            }
        }

        .ghorst-print {
            display: none;
        }

        table {
            font-size: 8px;
        }

        tr:nth-of-type(2n+1) {
            background-color: white;
        }

        th,
        td {
            padding-top: 0;
            padding-bottom: 0;
        }

        .fs-s {
            font-size: 7px;
        }
    }
}

/*! purgecss end ignore */
