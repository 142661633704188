//mixins

//------------------------------ Media Query(pc-first)
$wpc: 1920px; //pc @include pc
$pc: 1240px; //pc @include pc
$padpc: 1024px; //パッド @include pad 1024 iPad縦向き時にPC表示
$pad: 1023px; //パッド @include pad 1023
$tab: 834px; //タブレット @include tab 834
$stab: 600px;
//小さいタブレット @include stab 599 //$sstab: 556px; もっと小さいタブレット @include sstab
$sp: 480px; //スマホ @include sp 480
$ssp: 320px; //小さいスマホ @include ssp 356

@mixin wpc {
    @media (max-width: ($wpc)) {
        @content;
    }
}

@mixin pc {
    @media (max-width: ($pc)) {
        @content;
    }
}

@mixin padpc {
    @media (max-width: ($padpc)) {
        @content;
    }
}

@mixin pad {
    @media (max-width: ($pad)) {
        @content;
    }
}

@mixin tab {
    @media (max-width: ($tab)) {
        @content;
    }
}

@mixin stab {
    @media (max-width: ($stab)) {
        @content;
    }
}


@mixin sp {
    @media (max-width: ($sp)) {
        @content;
    }
}

@mixin ssp {
    @media (max-width: ($ssp)) {
        @content;
    }
}
